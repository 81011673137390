<template>
  <!-- COMPONENTE ONDE SURGE O DETALHE DO PRODUTO -->
  <div>
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <div class="card">
      <h3>
        <u>{{ name }}</u> <a @click="editProductName">✏️</a>
      </h3>
      <br />
      <b-container>
        <!-- b-row SERVE PARA SURGIR EM LINHA A IMAGEM COM OS DETALHES -->
        <div class="row">
          <div class="col-sm-4">
            <a class="edit" @click="openModalImg">✏️</a>
            <div class="box box1">
              <img :src="img" />
            </div>
          </div>
          <div class="col">
            <!-- OS VÁRIOS CAMPOS DO FORMULÁRIO ONDE SURGE OS DETALHES DO PRODUTO -->
            <form v-on:submit.prevent="editProduct()" class="row">
              <!-- EAN - EDITÁVEL -->
              <div class="col-md-6">
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">EAN</span>
                  </div>
                  <input type="text" class="form-control form-control-sm" id="ean" v-model="new_ean" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-group input-group-sm">
                  <div class="form-control form-control-sm" id="ean">
                    <a v-for="(e, index) in ean" :key="index">
                      <a v-if="index < ean.length - 1">{{ e + ' / ' }}</a>
                      <a v-else>{{ e }}</a>
                    </a>
                  </div>
                </div>
              </div>
              <!-- SKU - EDITÁVEL -->
              <div class="col-md-6">
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">SKU</span>
                  </div>
                  <input type="text" class="form-control form-control-sm" id="sku" v-model="new_sku" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-group input-group-sm">
                  <div class="form-control form-control-sm" id="sku">
                    <a v-for="(e, index) in sku" :key="index">
                      <a v-if="index < sku.length - 1">{{ e + ' / ' }}</a>
                      <a v-else>{{ e }}</a>
                    </a>
                  </div>
                </div>
              </div>
              <!-- OBSERVAÇÔES DO ARMAZÉM - EDITÁVEL -->
              <div class="col-md-6">
                <v-select
                  :options="status"
                  v-model="stockStatus"
                  label="stock_status"
                  @input="updateStockStatus()"
                  id="status"
                >
                </v-select>
              </div>
              <div class="col-md-6">
                <v-select
                  :options="price_options"
                  v-model="priceOption"
                  label="price_options"
                  @input="updatePriceOption()"
                  id="status"
                >
                </v-select>
              </div>
              <!-- HSCODE - EDITÁVEL -->
              <div class="col-md-6">
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">HS Code</span>
                  </div>
                  <input type="text" class="form-control form-control-sm" id="hscode" v-model="hscode" />
                </div>
              </div>
              <!-- PESO - EDITÁVEL -->
              <div class="col-md-6">
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">Peso (g)</span>
                  </div>
                  <input type="text" class="form-control form-control-sm" id="weight" v-model="weight" />
                </div>
              </div>
              <!-- STOCK - NÃO EDITÁVEL -->
              <div class="col-md-5">
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">Stock</span>
                  </div>
                  <input type="text" class="form-control form-control-sm" id="stock" v-model="stock" disabled />
                </div>
              </div>
              <div class="col-md-1"><a v-b-modal.modal-1>✏️</a></div>
              <div class="col-md-6">
                <!-- PREÇO ATUAL - NÃO EDITÁVEL -->
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">Preço Médio</span>
                  </div>
                  <input type="text" class="form-control form-control-sm" id="price" v-model="avarage" disabled />
                </div>
              </div>
              <div class="col-md-6">
                <!-- PREÇO ATUAL - NÃO EDITÁVEL -->
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">Validade</span>
                  </div>
                  <div>
                    <date-picker
                      v-model="validity"
                      type="month"
                      format="YYYY-MM"
                      value-type="format"
                      :lang="lang"
                    ></date-picker>
                  </div>
                </div>
                <span id="infinity" @click="setDataInfinity()"> Não tem validade </span>
              </div>
              <div class="col-md-6">
                <!-- O PRODUTO É UM AEROSOL? - EDITÁVEL -->
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">Aerosol</span>
                  </div>
                  <select class="form-control form-control-sm" v-model="aerosol" @change="updateAerosol()">
                    <option value="null" disabled>É um aerosol?</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </div>
              </div>
              <!-- VAZIO PARA AMBAS AS NOTAS FICAREM COM AS MESMAS DIMENSÕES -->
              <div class="col-md-6">
                <div class="input-group input-group-sm">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Marca</span>
                  <v-select
                    :options="allBrands"
                    v-model="brand"
                    label="name"
                    @input="addBrandToProduct()"
                    style="width: 82%"
                  >
                    <template slot="option" slot-scope="option"> {{ option.name }}</template>
                  </v-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">Notas Armz.</span>
                  </div>
                  <textarea
                    type="text"
                    class="form-control form-control-sm"
                    id="notesWarehouse"
                    rows="4"
                    cols="50"
                    v-model="notesWarehouse"
                    style="resize: none"
                  ></textarea>
                </div>
              </div>
              <!-- OBSERVAÇÕES FINAIS - EDITÁVEL -->
              <div class="col-md-6">
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">Notas Fina.</span>
                  </div>
                  <textarea
                    type="text"
                    class="form-control form-control-sm"
                    id="notesFinish"
                    rows="4"
                    cols="50"
                    v-model="notesFinish"
                    style="resize: none"
                  ></textarea>
                </div>
              </div>

              <!-- BUTÃO PARA SUBMETER AS ALTERAÇÕES -->
              <div class="form-group row">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-primary">Editar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-container>
    </div>
    <b-modal ref="my-modal" id="modal-1" title="Atualizar Stock" hide-footer>
      <form v-on:submit.prevent="fixStock()">
        <div class="form-group">
          <label for="exampleFormControlSelect1">Motivo:</label>
          <v-select v-model="reason" :options="reasons"> </v-select>
        </div>
        <div class="form-group">
          <label for="addressInput">Quantidade:</label>
          <input type="number" class="form-control" id="addressInput" v-model="qty" required />
        </div>
        <div class="form-group">
          <label for="addressInput">Comentários:</label>
          <textarea class="form-control" rows="2" v-model="comment" required></textarea>
        </div>
        <div id="btnUpdateAddress">
          <button type="submit" class="btn btn-primary">Guardar Alterações</button>
        </div>
      </form>
    </b-modal>
    <b-modal ref="my-modal-img" id="my-modal-img" title="Editar Imagem" hide-footer>
      <form v-on:submit.prevent="editImgLink()">
        <div class="form-group">
          <label for="addressInput">Link da Imagem:</label>
          <input type="text" class="form-control" id="addressInput" v-model="imgEdit" required />
        </div>
        <img class="editImg" :src="imgEdit" alt="" />
        <div id="btnUpdateAddress">
          <button type="submit" class="btn btn-primary">Guardar Alterações</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import Loading from '@/components/Loading.vue';
export default {
  components: {
    Loading,
  },
  /* PROPRIEDADES QUE RECEBEMOS DO COMPONENTE PAI */
  props: {
    name: String,
    ean: String,
    sku: String,
    stock: Number,
    weight: Number,
    price: String,
    avarage: String,
    img: String,
    notesWarehouse: String,
    notesFinish: String,
    priceRegular: String,
    id: String,
    hscode: String,
    country: String,
    eanhold: String,
    sku_old: String,
    validity: Date,
    stockStatus: String,
    priceOption: String,
    aerosol: Boolean,
    brand: Object,
    allBrands: Array,
  },
  data: function () {
    return {
      new: true,
      qty: 0,
      reasons: ['Acerto', 'Abate Fiscal', 'Produto Danificado'],
      reason: '',
      comment: '',
      loading: false,
      new_ean: '',
      new_sku: '',
      selectedBrand: this.brand_id,
      imgEdit: '',
      status: [
        'Infinity',
        'Normal',
        'Normalix',
        'Zero',
        'Discontinued',
        'MinC No',
        'MinC Low',
        'MinC Mid',
        'MinC High',
      ],
      price_options: ['Off', 'Auto', 'Level_1', 'Level_2', 'Level_3'],
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: [
            'janeiro',
            'fevereiro',
            'março',
            'abril',
            'maio',
            'junho',
            'julho',
            'agosto',
            'setembro',
            'outubro',
            'novembro',
            'dezembro',
          ],
          // MMM
          monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
          // dddd
          weekdays: [
            'Domingo',
            'Segunda-feira',
            'Terça-feira',
            'Quarta-feira',
            'Quinta-feira',
            'Sexta-feira',
            'Sábado',
          ],
          // ddd
          weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          // dd
          weekdaysMin: ['Do', 'Se', 'Te', 'Qa', 'Qi', 'Se', 'Sa'],
        },
      },
    };
  },
  methods: {
    /* FUNÇÃO PARA EDITAR O PRODUTO */
    async editProduct() {
      this.loading = true;
      try {
        await this.$store.dispatch('putProduct', {
          id: this.id,
          ean: this.new_ean,
          sku: this.new_sku,
          sku_old: this.sku_old,
          warehouse: this.notesWarehouse,
          finish: this.notesFinish,
          weight: this.weight,
          hscode: this.hscode,
          country: this.country,
          new: this.new,
          validity: this.validity,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Produto Atualizado',
          showConfirmButton: false,
          timer: 1500,
        });
        this.new = true;
      } catch (err) {
        alert(err);
      } finally {
        await this.$parent.getProduct();
        this.loading = false;
      }
    },
    async fixStock() {
      console.log('Entrou');
      if (this.reason == '' || this.qty == 0) {
        this.$swal({
          title: 'ERRO',
          text: 'Campos Obrigatórios faltam preencher!',
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK',
        });
      } else if (Number(this.stock) + Number(this.qty) < 0) {
        this.$swal({
          title: 'A atualização de stock não poderá ser processada porque o stock irá ficar negativo!',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok!',
        });
      } else {
        this.$swal({
          title: 'Quer mesmo atualizar o stock?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, atualizar!',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            try {
              this.loading = true;
              await this.$store.dispatch('fixStock', {
                id: this.id,
                qty: this.qty,
                reason: this.reason,
                comment: this.comment,
              });
              this.qty = 0;
              this.reason = '';
              this.comment = '';
              this.$refs['my-modal'].hide();
              await this.$parent.getProduct();
              await this.$parent.getTrans();
            } catch (err) {
              alert(err);
            } finally {
              this.loading = false;
            }
          }
        });
      }
    },
    async updateAerosol() {
      try {
        await this.$store.dispatch('updateAerosolProduct', {
          id: this.id,
          aerosol: this.aerosol,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Produto atualizado com sucesso',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async setDataInfinity() {
      console.log('Infinito');
      this.validity = '2050-01';
    },
    async updateStockStatus() {
      this.loading = true;
      try {
        await this.$store.dispatch('updateStockStatus', {
          id: this.id,
          status: this.stockStatus,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Stock Status Atualizado',
          showConfirmButton: false,
          timer: 1500,
        });
        this.new = true;
      } catch (err) {
        alert(err);
      }
      this.loading = false;
    },
    async updatePriceOption() {
      this.loading = true;
      try {
        await this.$store.dispatch('updatePriceOption', {
          id: this.id,
          status: this.priceOption,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Price Option Atualizado',
          showConfirmButton: false,
          timer: 1500,
        });
        this.new = true;
      } catch (err) {
        alert(err);
      }
      this.loading = false;
    },
    async addBrandToProduct() {
      try {
        await this.$store.dispatch('updateProductBrand', {
          id: this.id,
          brand: this.brand.id,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Marca adicionada ao produto com sucesso',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async editProductName() {
      this.$swal({
        title: 'Editar Nome do Produto',
        input: 'text',
        inputValue: this.name,
        showCancelButton: true,
        confirmButtonText: 'Editar',
        showLoaderOnConfirm: true,
        preConfirm: async (name) => {
          this.$swal({
            title: 'Alterar nome?',
            text: 'Tem a certeza que quer alterar o nome do produto?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, alterar!',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.name = name;
              try {
                await this.$store.dispatch('editProductName', {
                  id: this.id,
                  name: name,
                });
                this.$swal({
                  toast: true,
                  icon: 'success',
                  title: 'Nome do produto atualizado com sucesso',
                  showConfirmButton: false,
                  timer: 3000,
                  position: 'top-end',
                });
                this.new = true;
              } catch (err) {
                alert(err);
              }
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
    },
    async openModalImg() {
      this.imgEdit = this.img;
      this.$refs['my-modal-img'].show();
    },
    async editImgLink() {
      this.$swal({
        title: 'Alterar link?',
        text: 'Tem a certeza que quer alterar o link da imagem?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, alterar!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('editProductImage', {
              id: this.id,
              img: this.imgEdit,
            });
            this.$swal({
              toast: true,
              icon: 'success',
              title: 'Link da imagem atualizado com sucesso',
              showConfirmButton: false,
              timer: 3000,
              position: 'top-end',
            });
            this.new = true;
            this.img = this.imgEdit;
            this.$refs['my-modal-img'].hide();
          } catch (err) {
            alert(err);
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.form {
  width: 200px;
}
.form-group {
  margin: 5px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button {
  margin-top: 20px;
  float: right;
  background-color: rgb(104, 155, 75);
  border: none;
}
button:hover {
  background-color: rgb(74, 106, 192);
}
h3 {
  color: white;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
img {
  max-width: 80%;
}
.col-md-6 {
  margin-bottom: 5px;
}

.edit {
  position: absolute;
  margin-top: 10px;
  margin-left: 50px;
}
.input-group-text {
  background-color: rgb(74, 106, 192);
  color: white;
}
.box img {
  width: 100%;
  height: 200px;
}
.box1 img {
  object-fit: contain;
}
#infinity:hover {
  cursor: pointer;
  color: aqua;
}
#status {
  z-index: 100px;
  font-size: 13px;
}

a {
  cursor: pointer;
}

.editImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>
